export default {
    data: [],
    calculatedDeliveryPrice: null,
    total: {
        totalItems: 5,
        totalPages: 5,
    },
    detail: {
        driver: {
            id: 0
        },

    }
};
