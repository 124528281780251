import i18n from '@/libs/i18n';
import Vue from 'vue';
import OrderToastification from '@/components/OrderToastification.vue';
import router from '@/router';

export default {
    setData: (state, data) => {
        // console.log(data);
        state.data = data.content;
        state.total = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },


    updateData: (state, data) => {
        if (data.is_order) {
            let temp = state.data;

            if (data.is_review) {
                data.review = JSON.parse(data.review)
            }
            state.data = temp.map((item) => item.id == data.id ? {
                ...item,
                ...data
            } : item)

            if (state.detail) {
                if (state.detail.id == data.id) {
                    state.detail = {
                        ...state.detail,
                        ...data
                    }
                }
            }
        } else {
            if (data.is_new_order) {
                // Can accept an Object of options
                let notificationTitle = i18n.t('notifications.new_order_title');
                let notificationBody = i18n.t('notifications.new_order_body', {'no': data.no});

                if (('change_schedule_state' in data) && data.change_schedule_state==1) {
                    notificationTitle = i18n.t('notifications.change_schedule_title');
                    notificationBody = i18n.t('notifications.change_schedule_body', {'no': data.no});
                }
                if (!state.newOrders.some((item) => item.id == data.id)) {
                    // store.commit('successMessage', 'toggle', {root: true});
                    state.newOrders.unshift(data)
                    const audio = new Audio('/notification.mp3')
                    audio.play()
                    Vue.$toast(
                      {
                          component: OrderToastification,
                          props: {
                              orderId: data.id,
                              title: notificationTitle,
                              btn: i18n.t('common.show_details'),
                              body: notificationBody
                          },
                          listeners: {
                              goToDetail: () => {
                                  router.push({
                                      name: 'order-detail',
                                      params: {lang: i18n.locale, id: data.id},
                                  });
                              }  // Optional
                          }
                      },
                      {
                          toastClassName: "new-order-notification",
                          position: "bottom-right",
                          timeout: 0,

                      },
                    );

                }
            }
            if (!state.data.some((item) => item.id == data.id)) {
                state.data.unshift(data)
            }
        }
    },

    updateOrderDetail: (state, data) => {

        if (state.detail.id == data.id) {
            state.detail = {
                ...state.detail,
                ...data,
            }
        }

    },

    setCalculatedDeliveryPrice: (state, data) => {
        state.calculatedDeliveryPrice = data
    },

    setDetail: (state, data) => {
        state.detail = data;
    }

};
