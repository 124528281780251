import i18n from "@/libs/i18n";
import router from "@/router";

export default {
    async get({commit}) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`/talka_business/vehicles`)
            .then(respo => {
                console.log(respo.data.data)
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async unsetData({commit}) {
        commit('unsetData');
    },

    async simpleDetail({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`users/${data.type}/simple/${data.id}`)
            .then(respo => {
                if (respo.status === 200) {
                    console.log(respo.data.data);
                    commit('setDetail', respo.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async unsetDetail({commit}) {
        commit('unsetDetail');
    },

    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        return await this._vm.$http
            .post(`users/toggle`, data)
            .then(respo => {
                if (respo.status === 200) {
                    commit('updateData', respo.data.data);

                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                    return respo.data.data
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async create({commit}, data) {
        commit('loadingStart', null, {root: true});
        // console.log(data);
        await this._vm.$http
            .post(`talka_business/save_provider`, data)
            .then(respo => {
                console.log(respo)
                if (respo.status === 201) {
                    router.push({name: `talqah-business-providers`, params: {lang: i18n.locale}});

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`users/${data.type}/update/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: `users-${data.type}`, params: {lang: i18n.locale}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
};
